$error-message-height: 28px;
$error-message-margin-top: 10px;

.errorMessage {
  height: $error-message-height;  
  border-radius: 2px;
  font-size: 12px;
  line-height: 28px;
  color: #f44336;
  padding-left: 5px;
  position: relative;
  visibility: visible;
  margin: 0;
  margin-top: $error-message-margin-top;
}
.hidden {
  visibility: hidden;
}
