.userRoleChip {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  text-transform: capitalize;

  @media screen and (max-height: 700px) {
    span {
      font-size: 12px;
    }
  }
}

.userDetailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.websiteLogoToolbar {
  display: flex;
  align-items: flex-start;
  height: 20px;
  justify-content: center;

  svg {
    font-size: 200px;
    height: 20px;
  }

  @media screen and (max-height: 700px) {
    svg {
      font-size: 180px;
      height: 20px;
    }
  }
}

.customListItemButton {
  border-radius: 8px;
  padding-left: 0;

  @media screen and (max-height: 700px) {
    padding: 4px 16px 4px 0;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &:focus {
    background: rgba(255, 255, 255, 0.1);
  }
}

.menuSubHeader {
  margin-top: 10;

  @media screen and (max-height: 700px) {
    font-size: 12px;
  }
}

.customListItemIcon {
  min-width: 0;
  margin: 0 4.5px;

  svg {
    font-size: 24px;
  }

  @media screen and (max-height: 700px) {
    svg {
      font-size: 18px;
    }
  }
}

.customListItemText {
  span {
    font-size: 16px;
  }

  @media screen and (max-height: 700px) {
    span {
      font-size: 12px;
    }
  }
}

.customListItemIcon {
  min-width: 0;
  margin: 0 4.5px;
  font-size: 16px;
}

.customListItemText {
  font-size: 16px;
}

.customAppBar {
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
  }
}

.toggleIconButton {
  margin-right: 2;

  @media screen and (min-width: 600px) {
    display: 'none';
  }
}

.boxForDrawers {
  @media screen and (min-width: 600px) {
    width: 165px;
    flex-shrink: 0;
  }
}

.temporaryDrawer {
  width: 0;
  flex-shrink: 0;
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
  }
}

.permanentDrawer {
  flex-shrink: 0;
  display: block;

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.permanentDrawerPaper {
  overflow-x: hidden;
  display: flex;

  @media screen and (max-width: 600px) {
    display: none;
  }

  flex-direction: column;
  justify-content: space-between;
  padding: 24px 16px;
  width: 165px;
  box-sizing: border-box;
}

.temporaryDrawerPaper {
  overflow-x: hidden;
  display: none;

  @media screen and (max-width: 600px) {
    display: flex;
  }

  flex-direction: column;
  justify-content: space-between;
  padding: 24px 16px;
  width: 165px;
  box-sizing: border-box;
}