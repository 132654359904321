@import 'styles/palette.scss';

.errorPage {
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  
  img {
    max-width: 450px;
  }
}

.errorText {
  color: $text-secondary;
}