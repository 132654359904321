$submit-button-height: 42px;
$inputs-button-margin: 48px;
$header-form-margin: 48px;
$header-font-size: 34px;
$error-message-height: 28px;
$error-message-margin-top: 10px;
$subheader-height: 48px;
$login-container-padding: 48px;

.loginBoxContainer {
  width: 60vw;
  max-width: 600px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: $login-container-padding;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
}

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: $header-font-size;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
}

.subheader {
  height: $subheader-height;
  font-size: 16px;
  line-height: 150%;
  margin-top: $header-form-margin;
}
.formContainer {
  width: 100%;
  margin-top: $header-form-margin;
  background-color: white;
}

.submitButton {
  height: $submit-button-height;
  margin-top: calc(
    #{$inputs-button-margin} - #{$error-message-height} - #{$error-message-margin-top}
  );
}
