$submit-button-height: 42px;
$inputs-button-margin: 48px;
$header-form-margin: 48px;
$margin-between-inputs: 32px;
$input-height: 42px;
$header-font-size: 34px;
$error-message-height: 28px;
$error-message-margin-top: 10px;
$subheader-height: 48px;

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: $header-font-size;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
}

.subheader {
  height: $subheader-height;
  font-size: 16px;
  line-height: 150%;
  margin-top: $header-form-margin;
}
.formContainer {
  width: 100%;
  margin-top: $header-form-margin;
  background-color: white;
}

.codeInput {
  border: 1px solid grey;
  border-radius: 5px;
  animation: none;
  height: $input-height;
  padding: 5px;
  margin-bottom: $margin-between-inputs;

  &::before {
    border-bottom: none;
  }
  &::after {
    border-bottom: none;
  }
}

.submitButton {
  height: $submit-button-height;
  margin-top: $inputs-button-margin;
}

.errorMessage {
  height: $error-message-height;  
  border-radius: 2px;
  font-size: 12px;
  line-height: 28px;
  color: #f44336;
  padding-left: 5px;
  position: relative;
  visibility: visible;
  margin: 0;
  margin-top: $error-message-margin-top;
}
.hidden {
  visibility: hidden;
}

.returnToSignin {
  height: 42px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 15px;
}
