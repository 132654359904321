$header-container-height: 84px;
$form-container-height: 286px;
$submit-button-height: 42px;
$inputs-button-margin: 84px;
$header-form-margin: 48px;
$margin-between-inputs: 32px;
$input-height: 42px;
$login-container-padding: 48px;
$header-font-size: 34px;
$error-message-height: 28px;
$error-message-margin-top: 10px;
$login-container-padding: 48px;

.loginBoxContainer {
  width: 60vw;
  max-width: 600px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: $login-container-padding;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
}

.headerContainer {
  width: 100%;
  height: $header-container-height;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: $header-font-size;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
}
.formContainer {
  width: 100%;
  height: $form-container-height;
  margin-top: $header-form-margin;
  background-color: white;
}

.passwordLabelsContainer {
  display: flex;
  justify-content: space-between;
}

.passwordContainer {
  margin-top: $margin-between-inputs;
}

.usernameInput,
.passwordInput {
  border: 1px solid grey;
  border-radius: 5px;
  animation: none;
  height: $input-height;
  padding: 5px;

  &::before {
    border-bottom: none;
  }
  &::after {
    border-bottom: none;
  }
}

.submitButton {
  height: $submit-button-height;
  margin-top: calc(
    #{$inputs-button-margin} - #{$error-message-height} - #{$error-message-margin-top}
  );
}

.forgotPassword {
  font-weight: 400;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 133%;
  -webkit-transform: translate(0, -1.5px) scale(0.75);
  -moz-transform: translate(0, -1.5px) scale(0.75);
  -ms-transform: translate(0, -1.5px) scale(0.75);
  transform: translate(0, -1.5px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.errorMessage {
  height: $error-message-height;  
  border-radius: 2px;
  font-size: 12px;
  line-height: 28px;
  color: #f44336;
  padding-left: 5px;
  position: relative;
  visibility: visible;
  margin: 0;
  margin-top: $error-message-margin-top;
}
.hidden {
  visibility: hidden;
}
