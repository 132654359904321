.emptyPageContainer {
  display: flex;
  margin: 100px 20px 20px 20px;
  justify-content: center;
  align-items: center;
}

.emptyMessageBox {
  max-width: 451px;
  max-height: 358px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  h2 {
    font-size: 20px;
  }
}

.emptyMessageImage {
  height: inherit;
  width: inherit;
  object-fit: contain;
}