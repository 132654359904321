@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: local('Poppins'), url(./fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: local('Poppins'), url(./fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: local('Poppins'), url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  src: local('Poppins'), url(./fonts/Poppins-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  src: local('Poppins'), url(./fonts/Poppins-Black.ttf) format('truetype');
}

body {
  margin: 0;
}
