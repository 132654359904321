$margin-between-inputs: 32px;
$input-height: 42px;

.passwordLabelsContainer {
  display: flex;
  justify-content: space-between;
}

.confirmPasswordContainer {
  margin-top: $margin-between-inputs;
}

.newPasswordInput,
.confirmPasswordInput {
  border: 1px solid grey;
  border-radius: 5px;
  animation: none;
  height: $input-height;
  padding: 5px;

  &::before {
    border-bottom: none;
  }
  &::after {
    border-bottom: none;
  }
}
